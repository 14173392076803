<template>
    <div class="business-info">
        <div class="bar">
            <div class="bar-title">业务资料</div>
            <div class="bar-line"></div>
        </div>
        <div class="op-line">
            <div class="title">业务类型</div>
            <el-select
                v-model="businessType"
                size="small"
                @change="handleChange"
            >
                <el-option
                    v-for="item in businessSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
            <div class="title">船代</div>
            <el-select v-model="agencyType" size="small" @change="handleChange">
                <el-option
                    v-for="item in agencySelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
        </div>
        <div class="content">
            <el-table
                class="business-info-table"
                width="100%"
                :data="tableData"
                border
            >
                <el-table-column
                    prop="businessTypeName"
                    label="业务类型"
                    width="189"
                ></el-table-column>
                <el-table-column
                    prop="shippingAgentName"
                    label="船代"
                ></el-table-column>
                <el-table-column
                    prop="templateName"
                    label="文件名"
                ></el-table-column>
                <el-table-column prop="modifyTime" label="上传时间">
                    <template slot-scope="scope">
                        <span>
                            {{ handleFormatTime(scope.row.modifyTime) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="action" label="操作">
                    <template slot-scope="scope">
                        <el-link @click="handleDownload(scope.row)" type="primary">下载</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import Moment from "moment";
const ALL_DATA = [
    {
        id: 87,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 2,
        shippingAgentName: "上海航华",
        templateName: "上海航华-柜台删单资料.zip",
        templateUrl: "1808116298173194242",
        createTime: "2024-07-02T12:31:38.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 70,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 11,
        shippingAgentName: "上海振华",
        templateName: "上海振华-未上船证明资料.zip",
        templateUrl: "1808115608143077378",
        createTime: "2023-05-04T06:42:21.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        modifyBy: "季诗雨",
        deleteStatus: 0,
    },
    {
        id: 86,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 3,
        shippingAgentName: "上海中外运",
        templateName: "上海中外运-未上船证明资料.zip",
        templateUrl: "1808114662612738050",
        createTime: "2024-07-02T12:25:30.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 72,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 9,
        shippingAgentName: "上海顺德",
        templateName: "上海顺德-未上船证明资料.zip",
        templateUrl: "1693558315589001217",
        createTime: "2023-06-15T09:05:16.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        modifyBy: "季诗雨",
        deleteStatus: 0,
    },
    {
        id: 85,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 12,
        shippingAgentName: "鹏华",
        templateName: "鹏华-柜台删单资料.zip",
        templateUrl: "1693556592141099009",
        createTime: "2023-08-21T09:32:13.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 84,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 1,
        shippingAgentName: "上港联合",
        templateName: "上港联合-柜台删单资料.zip",
        templateUrl: "1693556074247168002",
        createTime: "2023-08-21T09:30:09.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 80,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 5,
        shippingAgentName: "上海中联",
        templateName: "上海中联-柜台删单资料.zip",
        templateUrl: "1693554482659794946",
        createTime: "2023-08-21T09:23:51.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 79,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 6,
        shippingAgentName: "上海鹏海",
        templateName: "上海鹏海-柜台删单资料.zip",
        templateUrl: "1693554402592776193",
        createTime: "2023-08-21T09:23:33.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 78,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 7,
        shippingAgentName: "上海华港",
        templateName: "上海华港-柜台删单资料.zip",
        templateUrl: "1693554189115666434",
        createTime: "2023-08-21T09:22:42.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 77,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 8,
        shippingAgentName: "东方海外",
        templateName: "东方海外-柜台删单资料.zip",
        templateUrl: "1693554068663263233",
        createTime: "2023-08-21T09:22:12.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 76,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 9,
        shippingAgentName: "上海顺德",
        templateName: "上海顺德-柜台删单资料.zip",
        templateUrl: "1693553475169628162",
        createTime: "2023-08-21T09:19:50.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 75,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 10,
        shippingAgentName: "上海民生",
        templateName: "上海民生-柜台删单资料.zip",
        templateUrl: "1693553254612152321",
        createTime: "2023-08-21T09:19:08.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 74,
        businessTypeId: 2,
        businessTypeName: "柜台删单",
        shippingAgentId: 11,
        shippingAgentName: "上海振华",
        templateName: "上海振华-柜台删单资料.zip",
        templateUrl: "1693552986721955842",
        createTime: "2023-08-21T09:18:28.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 73,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 8,
        shippingAgentName: "东方海外",
        templateName: "东方海外-未上船证明资料.zip",
        templateUrl: "1684152499661668354",
        createTime: "2023-07-26T10:44:15.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 71,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 9,
        shippingAgentName: "上海顺德",
        templateName: "上海顺德-落货资料.zip",
        templateUrl: "1669266518908026883",
        createTime: "2023-06-15T08:54:37.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 69,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 1,
        shippingAgentName: "上港联合",
        templateName: "上港联合-未上船证明资料.rar",
        templateUrl: "1654012992663064578",
        createTime: "2023-05-04T06:40:05.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 68,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 2,
        shippingAgentName: "上海航华",
        templateName: "上海航华-未上船证明资料.rar",
        templateUrl: "1654012890119831554",
        createTime: "2023-05-04T06:39:46.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 66,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 4,
        shippingAgentName: "上海外代",
        templateName: "上海外代-未上船证明资料.rar",
        templateUrl: "1654012402822287362",
        createTime: "2023-05-04T06:37:45.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 65,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 5,
        shippingAgentName: "上海中联",
        templateName: "上海中联-未上船证明资料.rar",
        templateUrl: "1654012273692250113",
        createTime: "2023-05-04T06:37:24.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 64,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 7,
        shippingAgentName: "上海华港",
        templateName: "上海华港-未上船证明资料.rar",
        templateUrl: "1654011742751195137",
        createTime: "2023-05-04T06:35:07.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 61,
        businessTypeId: 1,
        businessTypeName: "未上船证明",
        shippingAgentId: 10,
        shippingAgentName: "上海民生",
        templateName: "上海民生-未上船证明资料.rar",
        templateUrl: "1654010762119360513",
        createTime: "2023-05-04T06:32:56.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 60,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 7,
        shippingAgentName: "上海华港",
        templateName: "上海华港-落货资料.zip",
        templateUrl: "1646722602468802562",
        createTime: "2023-04-14T03:50:39.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 59,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 1,
        shippingAgentName: "上港联合",
        templateName: "上港联合-落货资料.zip",
        templateUrl: "1646722522316361730",
        createTime: "2023-04-14T03:50:20.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 57,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 3,
        shippingAgentName: "上海中外运",
        templateName: "上海中外运-落货资料.zip",
        templateUrl: "1646722349666406401",
        createTime: "2023-04-14T03:49:52.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 33,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 8,
        shippingAgentName: "东方海外",
        templateName: "东方海外-落货资料.zip",
        templateUrl: "1497090655195312129",
        createTime: "2022-02-25T06:06:45.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 20,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 6,
        shippingAgentName: "上海鹏海",
        templateName: "上海鹏海-落货资料.zip",
        templateUrl: "1464141860471791618",
        createTime: "2021-11-25T05:46:42.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        modifyBy: "郑金梦",
        deleteStatus: 0,
    },
    {
        id: 26,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 5,
        shippingAgentName: "上海中联",
        templateName: "上海中联-落货资料.rar",
        templateUrl: "1463748826517168130",
        createTime: "2021-11-25T05:58:13.000+0000",
        createBy: "成钰清",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 24,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 4,
        shippingAgentName: "上海外代",
        templateName: "上海外代-落货资料.rar",
        templateUrl: "1463748634753589250",
        createTime: "2021-11-25T05:57:28.000+0000",
        createBy: "成钰清",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
    {
        id: 22,
        businessTypeId: 3,
        businessTypeName: "落货",
        shippingAgentId: 2,
        shippingAgentName: "上海航华",
        templateName: "上海航华-落货资料.zip",
        templateUrl: "1463746056481366017",
        createTime: "2021-11-25T05:47:13.000+0000",
        createBy: "季诗雨",
        modifyTime: "2024-11-27",
        deleteStatus: 0,
    },
];
export default {
    name: "BusinessInfo",
    data() {
        return {
            businessType: "",
            businessSelect: [
                {
                    label: "全部",
                    value: "",
                },
                {
                    label: "落货",
                    value: 3,
                },
                {
                    label: "柜台删单",
                    value: 2,
                },
                {
                    label: "未上船证明",
                    value: 1,
                },
            ],
            agencyType: "",
            agencySelect: [
                {
                    label: "全部",
                    value: "",
                },
                {
                    label: "鹏华",
                    value: 12,
                },
                {
                    label: "上海振华",
                    value: 11,
                },
                {
                    label: "上海民生",
                    value: 10,
                },
                {
                    label: "上海顺德",
                    value: 9,
                },
                {
                    label: "东方海外",
                    value: 8,
                },
                {
                    label: "上海华港",
                    value: 7,
                },
                {
                    label: "上海鹏海",
                    value: 6,
                },
                {
                    label: "上海中联",
                    value: 5,
                },
                {
                    label: "上海外代",
                    value: 4,
                },
                {
                    label: "上海中外运",
                    value: 3,
                },
                {
                    label: "上海航华",
                    value: 2,
                },
                {
                    label: "上港联合",
                    value: 1,
                },
            ],

            tableData: [...ALL_DATA],
        };
    },
    methods: {
        handleFormatTime(time) {
            return Moment(time).format("YYYY-MM-DD");
        },
        handleChange() {
            // console.log(this.businessType, this.agencyType);
            let data = [];

            if (this.businessType && this.agencyType) {
                data = ALL_DATA.filter(
                    (item) =>
                        item.businessTypeId === this.businessType &&
                        item.shippingAgentId === this.agencyType,
                );
            } else if (this.businessType) {
                data = ALL_DATA.filter(
                    (item) => item.businessTypeId === this.businessType,
                );
            } else if (this.agencyType) {
                data = ALL_DATA.filter(
                    (item) => item.shippingAgentId === this.agencyType,
                );
            } else {
                data = [...ALL_DATA];
            }

            this.tableData = data;
        },
        handleDownload(row) {
            window.open(`https://rocket-web-server.oss-cn-hangzhou.aliyuncs.com/static/fangcangwang/manifest-business/${row.templateName}`)
        },
    },
};
</script>
<style lang="stylus">
.business-info-table{
    .el-table__header-wrapper th{
        background-color: #e5e7eb !important;
        font-size:12px;
        color:#282828;
        font-weight: 700;
    }
    .el-table__cell{
        padding:7px 0;
        .cell{
            font-size:12px;
            color:#4b5669;
        }
    }
}
</style>
<style scoped lang="stylus">
.business-info{
    margin:16px 0;
    background-color: #fff;
    padding:12px;
    .bar{
        .bar-title{
            color:rgb(40,40,40);
            font-weight: 700;
            font-size:15.75px;
            padding:6px 8px 16px;
            line-height:24.5px;
        }
        .bar-line{
            height:1px;
            background-color:rgb(229,229,229);
        }
    }
    .op-line{
        display:flex;
        align-items:center;
        padding:7px 14px;
        gap:10px;
        .title{
            color:#282828;
            font-size:14px;
        }
    }
    .content{
        padding:17.5px;
        line-height:2;
        font-size:14px;
        color:#282828;
        p, span, div{
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }
        .center{
            text-align:center;
        }
        .title{
            color:#282828;
            font-size:14px;
        }
        .sub-title{
            margin-top: 10px;
            margin-bottom: 10px;
            border-width: 0px;
            border-left-width: 3px;
            border-style: solid;
            border-color: rgb(255,152,7);
            text-indent: 10px;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.5;
            color: rgb(255,152,7);
        }
        .flex{
            display:flex;
        }
        .flex-col{
            flex-direction:column;
        }
        .text-em{
            text-indent: 2em;
        }
    }
}
</style>
